/* eslint-disable no-unused-vars */
import React from 'react';
import _ from 'lodash';
import UserCard from './UserCard';
/* eslint-enable no-unused-vars */

export default function UserList({ users }) {
  function renderUsers() {
    return _.values(users).map((user) => {
      return <UserCard user={user} key={user._id} />;
    });
  }

  return (
    <div>{renderUsers()}</div>
  );
}

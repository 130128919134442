/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import {
  Button,
  ButtonToolbar,
  Col,
  Image,
  Form,
  Card,
  Container,
  Row,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import {
  textField,
} from '../../core/CoreForms';
/* eslint-enable no-unused-vars */

class UserEditForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);

    this.state = {
      id: this.props.auth ? this.props.auth.id : null,
      firstName: this.props.auth ? this.props.auth.firstName : '',
      lastName: this.props.auth ? this.props.auth.lastName : '',
      displayName: this.props.auth ? this.props.auth.displayName : '',
      email: this.props.auth ? this.props.auth.email : '',
      cell: this.props.auth ? this.props.auth.cell : '',
      perms: this.props.auth ? this.props.auth.perms : [],
      perm: 'N/A',
      errors: '',
      loading: false,
    };
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.auth) {
      this.setState({
        id: nextProps.auth.id ? nextProps.auth.id : null,
        firstName: nextProps.auth.firstName,
        lastName: nextProps.auth.lastName,
        email: nextProps.auth.email,
        displayName: nextProps.auth.displayName,
        cell: nextProps.auth.cell,
        perms: nextProps.auth.perms,
        perm: 'N/A',
      });
    }
  };

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    const { id, firstName, lastName, cell } = this.state;
    this.setState({ loading: true });
    this.props
      .updateCurrentUser({
        id,
        firstName,
        lastName,
        cell,
      })
      .catch((err) =>
        err.response
          .json()
          .then(({ errors }) => this.setState({ errors, loading: false }))
      );
  };

  okCancelButtonTooolBar() {
    return (
      <ButtonToolbar>
        <LinkContainer to="/account">
          <Button variant="warning">Cancel</Button>
        </LinkContainer>
        <Button type="submit" variant="primary">
          Save
        </Button>
      </ButtonToolbar>
    );
  }

  renderPhotoForm() {
    if (this.state.photos && this.state.photos.length > 0) {
      return this.state.photos.map((photo) => {
        return (
          <div key={photo.id}>
            <Image src={photo.amazons3URL} alt="Avatar" rounded responsive />
            <br />
          </div>
        );
      });
    } else {
      return (
        <div>
          <Image
            src="/images/FGFarmLogo150h.PNG"
            alt="Avatar"
            rounded
            responsive
          />
        </div>
      );
    }
  }

  /* eslint-disable max-len */
  render() {
    const form = (
      <Form
        className={classnames('ui', 'form', { loading: this.state.loading })}
        onSubmit={this.handleSubmit}
      >
        <h2>User Info</h2>
        <h4>Username: {this.state.email}</h4>
        <h4>Display Name: {this.state.displayName}</h4>
        {textField('firstName', 'First Name', this)}
        {textField('lastName', 'Last Name', this)}
        {textField('cell', 'Cell', this)}
        <div>{this.okCancelButtonTooolBar()}</div>
      </Form>
    );
    return (
      <Container>
        <Row className="show-grid">
          <Col xs={12} sm={12} md={12}>
            <Card variant="danger">
              <Card.Header>
                <Card.Title    >Edit User</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Body>{form}</Card.Body>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default UserEditForm;

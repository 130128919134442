'use strict'; // eslint-disable-line

Object.defineProperty(exports, '__esModule', {
  value: true,
});

var API_CURRENT_USER = (exports.API_CURRENT_USER = '/api/current_user'); // eslint-disable-line
var API_LOGOUT = (exports.API_LOGOUT = '/api/logout'); // eslint-disable-line
var API_STRIPE_HANDLE_TOKEN = (exports.API_STRIPE_HANDLE_TOKEN = // eslint-disable-line
  '/api/stripe/handletoken');

var AUTH_GOOGLE = (exports.AUTH_GOOGLE = '/auth/google'); // eslint-disable-line
var AUTH_GOOGLE_CALLBACK = (exports.AUTH_GOOGLE_CALLBACK = // eslint-disable-line
  '/auth/google/callback'); // eslint-disable-line

var AUTH_EMAIL = (exports.AUTH_EMAIL = '/auth/email'); // eslint-disable-line

var AUTH_FACEBOOK = (exports.AUTH_FACEBOOK = '/auth/facebook'); // eslint-disable-line
var AUTH_FACEBOOK_CALLBACK = (exports.AUTH_FACEBOOK_CALLBACK = // eslint-disable-line
  '/auth/facebook/callback'); // eslint-disable-line

var ADMIN_EVENTS = (exports.ADMIN_EVENTS = '/admin/events'); // eslint-disable-line
var ADMIN_EVENTS = (exports.ADMIN_FACILITIES = '/admin/facilities'); // eslint-disable-line
var ADMIN_USERS = (exports.ADMIN_USERS = '/admin/users'); // eslint-disable-line

// NORMAL URLS
var LANDING = (exports.LANDING = '/'); // eslint-disable-line
var SURVEYS = (exports.SURVEYS = '/surveys'); // eslint-disable-line
var SURVEY_NEW = (exports.SURVEY_NEW = '/survey/new'); // eslint-disable-line
var LOGIN = (exports.LOGIN = '/login'); // eslint-disable-line

// CUSTOMER URLS
var CUSTOMER_LANDING = (exports.LANDING = '/dash'); // eslint-disable-line
var CUSTOMER_MAIN = (exports.CUSTOMER_MAIN = '/main'); // eslint-disable-line
var HORSES = (exports.HORSES = '/horses'); // eslint-disable-line
var HORSES_NEW = (exports.HORSES_NEW = '/horses/new'); // eslint-disable-line
var HORSES_EDIT = (exports.HORSES_EDIT = '/horses/edit/:_id'); // eslint-disable-line
var ENTRIES = (exports.ENTRIES = '/entries'); // eslint-disable-line

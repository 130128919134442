/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import _ from 'lodash';
import classnames from 'classnames';
import { connect } from 'react-redux';
import {
  addPermToUser,
  removePermFromUser,
  updateAdminUser,
} from '../../../actions/userActions';
import {
  Button,
  ButtonToolbar,
  Col,
  Image,
  Form,
  Card,
  Container,
  Row,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import {
  okCancelChangesButtons,
  textField,
  objSelector,
} from '../../core/CoreForms';
/* eslint-enable no-unused-vars */

class UserAdminEditForm extends Component {
  constructor(props, context) {
    super(props, context);

    this.handleChange = this.handleChange.bind(this);
    this.onAddPermClick = this.onAddPermClick.bind(this);
    this.onRemovePermClick = this.onRemovePermClick.bind(this);
    this.handleSelChange = this.handleSelChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.createOptionObjects = this.createOptionObjects.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);

    this.state = {
      _id: this.props.user ? this.props.user._id : null,
      firstName: this.props.user ? this.props.user.firstName : '',
      lastName: this.props.user ? this.props.user.lastName : '',
      email: this.props.user ? this.props.user.email : '',
      cell: this.props.user ? this.props.user.cell : '',
      barnLimit: this.props.user ? this.props.user.barnLimit : 2,
      horseLimit: this.props.user ? this.props.user.horseLimit : 5,
      // type: this.props.user ? this.props.user.type : 0,
      // status: this.props.user ? this.props.user.status : 0,
      // photoBin: this.props.user ? this.props.user.photoBin : uuidv4(),
      perms: this.props.user ? this.props.user.perms : [],
      perm: 'N/A',
      errors: '',
      loading: false,
    };
  }

  handleSelectChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.user) {
      this.setState({
        _id: nextProps.user._id ? nextProps.user._id : null,
        firstName: nextProps.user.firstName,
        lastName: nextProps.user.lastName,
        email: nextProps.user.email,
        cell: nextProps.user.cell,
        barnLimit: nextProps.user.barnLimit,
        horseLimit: nextProps.user.horseLimit,
        perms: nextProps.user.perms,
        perm: 'N/A',
      });
    }
  };

  getUserBarns() {
    let assignedBarns = [];
    if (this.props.user && this.props.adminBarns) {
      let barnIdsArray = _.keys(this.props.adminBarns);
      let adminBarnsObj = this.props.adminBarns;
      let userId = this.props.user._id;
      barnIdsArray.forEach(function (barnId) {
        if (adminBarnsObj[barnId.toString()].users.indexOf(userId) > -1) {
          assignedBarns = assignedBarns.concat(
            adminBarnsObj[barnId.toString()]
          );
        }
      });
    }
    return (
      <div>
        <ul>
          {assignedBarns.map(function (barn) {
            return <li key={barn._id}>{barn.name}</li>;
          })}
        </ul>
      </div>
    );
  }

  getValidationState() {
    // const length = this.state.value.length;
    // if (length > 10) return 'success';
    // else if (length > 5) return 'warning';
    // else if (length > 0) return 'error';
    // return null;
  }

  handleChange = (e) => {
    if (e.target) {
      if (!!this.state.errors[e.target.id]) {
        //  gets a separate copy of errors
        let errors = Object.assign({}, this.state.errors);
        delete errors[e.target.id];
        this.setState({
          [e.target.id]: e.target.value,
          errors,
        });
      } else {
        this.setState({ [e.target.id]: e.target.value });
      }
    }
  };

  onAddPermClick() {
    if (this.state.perm !== '') {
      const { _id, perm } = this.state;
      this.props
        .addPermToUser({
          _id,
          perm,
        })
        .catch((err) =>
          err.response
            .json()
            .then(({ errors }) => this.setState({ errors, loading: false }))
        );
    }
  }

  onRemovePermClick() {
    if (this.state.perm !== '') {
      const { _id, perm } = this.state;
      this.props
        .removePermFromUser({
          _id,
          perm,
        })
        .catch((err) =>
          err.response
            .json()
            .then(({ errors }) => this.setState({ errors, loading: false }))
        );
    }
  }

  handleSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }

    // validation
    let errors = {};
    if (this.state.firstName === '') {
      errors.firstName = 'User Description required!';
    }
    if (this.state.lastName === '') {
      errors.lastName = 'Location required (If unknown enter UNK)!';
    }
    this.setState({ errors });
    const isValid = Object.keys(errors).length === 0;

    if (isValid) {
      const { _id, firstName, lastName, email, cell } = this.state;
      this.setState({ loading: true });
      this.props
        .updateAdminUser({
          _id,
          firstName,
          lastName,
          email,
          cell,
        })
        .catch((err) =>
          err.response
            .json()
            .then(({ errors }) => this.setState({ errors, loading: false }))
        );
    }
  };

  handleAddBarnSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    const { _id, firstName, lastName, email, cell } = this.state;
    this.setState({ loading: true });
    this.props
      .updateAdminUser({
        _id,
        firstName,
        lastName,
        email,
        cell,
      })
      .catch((err) =>
        err.response
          .json()
          .then(({ errors }) => this.setState({ errors, loading: false }))
      );
  };

  okCancelButtonTooolBar() {
    return (
      <ButtonToolbar>
        <LinkContainer to="/users">
          <Button variant="warning">Cancel</Button>
        </LinkContainer>
        <Button type="submit" variant="primary">
          Save
        </Button>
      </ButtonToolbar>
    );
  }

  handleSelChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  renderPhotoForm() {
    if (this.state.photos && this.state.photos.length > 0) {
      return this.state.photos.map((photo) => {
        return (
          <div key={photo._id}>
            <Image src={photo.amazons3URL} alt="Avatar" rounded responsive />
            <br />
          </div>
        );
      });
    } else {
      return (
        <div>
          <Image
            src="/images/FGFarmLogo150h.PNG"
            alt="Avatar"
            rounded
            responsive
          />
        </div>
      );
    }
  }

  permsToListGroup(perms) {
    return _.values(perms).map((permission) => {
      return <ListGroupItem key={permission}>{permission}</ListGroupItem>;
    });
  }

  createOptionObjects() {
    return _.map(this.props.adminBarns, (adminBarn) => {
      return { index: adminBarn._id, label: adminBarn.name };
    });
  }

  /* eslint-disable max-len */
  render() {
    const form = (
      <Form
        className={classnames('ui', 'form', { loading: this.state.loading })}
        onSubmit={this.handleSubmit}
      >
        <h2>User</h2>
        {textField('firstName', 'First Name', this)}
        {textField('lastName', 'Last Name', this)}
        {textField('email', 'Email', this)}
        {textField('cell', 'Cell', this)}
        <div>{this.okCancelButtonTooolBar()}</div>
      </Form>
    );
    const barnPanel = (
      <Card variant="warning">
        <Card.Header>Barns</Card.Header>
        <Card.Body>
          <ListGroup>{this.getUserBarns()}</ListGroup>
        </Card.Body>
        <Card.Footer>
          <Form
            className={classnames('ui', 'form', {
              loading: this.state.loading,
            })}
            onSubmit={this.handleAddBarnSubmit}
          >
            {objSelector('barnId', 'Barn', this, this.createOptionObjects())}
            {okCancelChangesButtons('/users')}
          </Form>
        </Card.Footer>
      </Card>
    );
    return (
      <Container>
        <Row className="show-grid">
          <Col xs={12} sm={12} md={12}>
            <Card variant="danger">
              <Card.Header>
                <Card.Title>Edit User</Card.Title>
              </Card.Header>
              <Card.Body>
                <Card.Body>{form}</Card.Body>
              </Card.Body>
              <Card variant="info">
                <Card.Header>Permissions</Card.Header>
                <Card.Body>
                  <ListGroup>
                    {this.permsToListGroup(this.state.perms)}
                  </ListGroup>
                </Card.Body>
                <Card.Footer>
                  <Form.Group controlId="perm">
                    <Form.Label>Permissions</Form.Label>
                    <Form.Control
                      componentClass="select"
                      value={this.state.perm}
                      onChange={this.handleSelChange}
                      name="perm"
                    >
                      <option value="">N/A</option>
                      <option value="WORK_ORDER_USER">WORK_ORDER_USER</option>
                      <option value="AEGON">AEGON</option>
                      <option value="WORK_ORDER_ADMIN">WORK_ORDER_ADMIN</option>
                      <option value="LOG_BOOK_ADMIN">LOG_BOOK_ADMIN</option>
                    </Form.Control>
                    <Button onClick={this.onAddPermClick} variant="primary">
                      Add Permission to User
                    </Button>
                    <Button onClick={this.onRemovePermClick} variant="warning">
                      Remove Permission from User
                    </Button>
                  </Form.Group>
                </Card.Footer>
              </Card>
              {barnPanel}
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default connect(null, {
  addPermToUser,
  removePermFromUser,
  updateAdminUser,
})(UserAdminEditForm);

/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Container, Card, Row, Col } from 'react-bootstrap';
import InitiateResetPassFormik from '../../formik/InitiateResetPassFormik';
/* eslint-enable no-unused-vars */

class InvalidToken extends Component {
  render() {
    const form = (
        <Card variant="warning">
          <Card.Header>
            <Card.Title    >
              Password Reset Token Expired
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <h3>
              <p>
                An email was previously sent to the email you provided with a
                link to reset your password.
              </p>
              <br />
              <p>
                Unfortunately, that Token has expired. For security reasons a
                Token is only good for 30 minutes.
              </p>
              <br />
              <p>
                To generate a new Token, please confirm your email and click
                "Reset Password".
              </p>
              <p>
                You should quickly receive a new email with a password reset
                link to click.
              </p>
            </h3>
          </Card.Body>
          <Card.Footer>
            <InitiateResetPassFormik />
          </Card.Footer>
        </Card>
    );
    return (
      <Container>
        <Row className="show-grid">
          <Col xs={12} sm={12} md={12}>
            {form}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default InvalidToken;

/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React, { Component } from 'react';
import { Card, Row, Col } from 'react-bootstrap';
/* eslint-enable no-unused-vars */

class ResetSent extends Component {
  render() {
    const form = (
      <div>
        <Card variant="warning">
          <Card.Header>
            <Card.Title    >Password Reset Sent</Card.Title>
          </Card.Header>
          <Card.Body>
            <h3>
              <p>
                An email has been sent to the email you provided with a link to
                reset your password.
              </p><br/>
              <p>
                Please check your email, note that it may take a few minutes to
                reach your Inbox.
              </p><br/>
              <p>Also note that the email MAY end up in your spam folder. </p>
              <p><br/>
                Once you have the email, please click the link to be redirected
                to the email reset page here at FullGallopFarm.com.
              </p>
              <p><br/>
                Thank you for your patience and our sincere apologies for any
                inconvenience.
              </p>
            </h3>
          </Card.Body>
        </Card>
      </div>
    );
    return (
      <div>
        <Row className="show-grid">
          <Col xs={12} sm={6} md={6}>
            {form}
          </Col>
        </Row>
      </div>
    );
  }
}

export default ResetSent;

/* eslint-disable no-unused-vars */
/* eslint-disable no-invalid-this */
import React from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment';
/* eslint-enable no-unused-vars */

export default function HorseDocumentCard({ horseDocument }) {
  function dateToText(date) {
    return <p>{moment(date).format('M/D/YY - hh:mm a')}</p>;
  }

  function getDisplayName() {
    if (horseDocument && horseDocument.title) {
      return horseDocument.title;
    } else {
      if (horseDocument && horseDocument.description) {
        return horseDocument.description;
      } else {
        return '';
      }
    }
  }

  function getUploadDate() {
    if (horseDocument && horseDocument.createdAt) {
      return dateToText(horseDocument.createdAt);
    } else {
      return 'Unknown Date';
    }
  }

  // handleDelete = (e) => {
  //   if (e) {
  //     e.preventDefault();
  //   }
  //   const horseDocumentId = horseDocument.id;
  //   onDelete({ horseDocumentId });
  // };

  //   function getDeleteButton() {
  // if (onDelete) {
  //   return (
  //     <Button onClick={this.handleDelete} variant="danger">
  //       Delete
  //     </Button>
  //   );
  // }
  //   }

  return (
    <td>
      <div className="card">
        <div className="container">
          <h4>{getDisplayName()}</h4>
          <h5>{getUploadDate()}</h5>
          {/* <p>{getDeleteButton()}</p> */}
          <Button href={horseDocument.amazons3URL} variant="warning">
            View Document
          </Button>
        </div>
      </div>
    </td>
  );
}
